






























import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import {
	defineComponent,
	onMounted,
	ref,
	useContext,
	useRouter,
} from "@nuxtjs/composition-api";
import type { PropType } from "@nuxtjs/composition-api";
import axios from "axios";

import type { ImageModifiers } from "@nuxt/image";
import Preload from "~/components/Preload.vue";
import VueSlickCarousel from "~/components/VueSlickCarousel.vue";
import { useConfig } from "~/composables";

export default defineComponent({
	name: "HeroSection",
	components: {
		VueSlickCarousel,
		Preload,
	},
	props: {
		title: {
			type: String,
			default: "",
		},
		subtitle: {
			type: String,
			default: "",
		},
		buttonText: {
			type: String,
			default: "",
		},
		imageHeight: {
			type: [Number, String],
			default: "",
		},
		imageSrc: {
			type: String,
			default: "",
		},
		imageTag: {
			type: String,
			default: "",
		},
		imageWidth: {
			type: [Number, String],
			default: "",
		},
		link: {
			type: String,
			default: "",
		},
		nuxtImgConfig: {
			type: Object as PropType<ImageModifiers | {}>,
			default: () => ({}),
		},
		bannerSlideId: {
			type: String,
			default: "",
		},
		banner_mobile_width: {
			type: String,
			default: "618",
		},
		banner_mobile_height: {
			type: String,
			default: "200",
		},
	},

	setup(props) {
		const { config } = useConfig();
		const slides = ref();
		const baseMediaUrl = config.value.secure_base_media_url;
		const baseUrl = config.value.secure_base_url;
		const swipeDetected = ref(true);
		const { app } = useContext();
		const router = useRouter();
		const isLoaded = ref(false);

    onMounted(async () => {
			const headers = {
				Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
			};

			if (props.bannerSlideId) {
				const response = await axios.get(
					`${baseUrl}rest/V1/utech/slider/${props.bannerSlideId}`,
					{
						headers,
					},
				);

				isLoaded.value = true;
				slides.value = response?.data[1];
			}
    })

		const linkBanner = (_: unknown, link: string, newtab: boolean) => {
			if (link && swipeDetected.value) {
				if (link.includes("http")) {
					if (newtab) {
						window.open(link, "_blank");
					} else {
						window.location.href = link;
					}
				} else {
					router.push(app.localePath(`/${link}`));
				}
			}
		};
		const handleSlideBeforeChange = () => {
			swipeDetected.value = false;
		};
		const handleSlideAfterChange = () => {
			swipeDetected.value = true;
		};

		return {
			slides,
			baseMediaUrl,
			isLoaded,
			linkBanner,
			handleSlideBeforeChange,
			handleSlideAfterChange,
		};
	},
	data() {
		return {
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						dots: false,
					},
				},
			],
		};
	},
});
