






























































import {
  defineComponent,
  onMounted,
  ref,
  useFetch,
} from "@nuxtjs/composition-api";
import { CacheTagPrefix, useCache } from "@vue-storefront/cache";
import axios from "axios";
import Blog from "~/components/Blog/Tabs.vue";
import FlashSaleHomepage from "~/components/FlashSales/Homepage.vue";
import HeroSection from "~/components/HeroSection.vue";
import MiniBanner from "~/components/MiniBanner.vue";
import { useConfig } from "~/composables";
import useApi from "~/composables/useApi";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import type { CategoryTree } from "~/modules/GraphQL/types";
import CategoryProductsGql from "./CategoryProducts.gql";
import Preload from "~/components/Preload.vue";
export default defineComponent({
  name: "HomePage",
  components: {
    Blog,
    HeroSection,
    MiniBanner,
    ContactUsForm: () =>
      import(/* webpackPrefetch: true */ "~/components/ContactUsForm.vue"),
    Shop: () =>
      import(/* webpackPrefetch: true */ "~/components/Aboutus/Shop.vue"),
    ServiceUs: () =>
      import(/* webpackPrefetch: true */ "~/components/Aboutus/ServiceUs.vue"),
    Installment: () =>
      import(
				/* webpackPrefetch: true */ "~/components/Aboutus/Installment.vue"
      ),
    AllBrandHomePage: () =>
      import(/* webpackPrefetch: true */ "~/components/AllBrandHomePage.vue"),
    CouponHomePage: () =>
      import(
				/* webpackPrefetch: true */ "~/components/Coupon/CouponHomePage.vue"
      ),
    CategoryProducts: () =>
      import(/* webpackPrefetch: true */ "~/components/CategoryProducts.vue"),
    HighlightProduct: () =>
      import(/* webpackPrefetch: true */ "~/components/HighlightProduct.vue"),
    FlashSaleHomepage,
    TextCategoryHighlight: () =>
      import(
				/* webpackPrefetch: true */ "~/components/TextCategoryHighlight.vue"
      ),
    CategoryList: () =>
      import(/* webpackPrefetch: true */ "~/components/CategoryList.vue"),
    CategoryListIcon: () =>
      import(/* webpackPrefetch: true */ "~/components/CategoryListIcon.vue"),
    StrapiBlog: () =>
      import(/* webpackPrefetch: true */ "~/components/Blog/StrapiBlog.vue"),
    Preload,
  },
  setup() {
    const { addTags } = useCache();
    const { config } = useConfig();
    const { query } = useApi();

    const baseMediaUrl = config.value.secure_base_media_url;
    const baseUrl = config.value.secure_base_url;

    const categoryData = ref([]);
    const isLoaded = ref(false);
    const bannerSlideId = ref();
    const homeCategoryId = ref();
    const miniBannerId = ref();
    const coupon_enabled = ref();
    const banner_mobile_width = ref();
    const banner_mobile_height = ref();
    const i = ref(0);

    const logo = config.value.header_logo_src;
    const logo_web = baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : "";

    const strapiBlog = process.env.STRAPI_BLOG_FF === 'true';

    const { fetch } = useFetch(async () => {
      const tags = [{ prefix: CacheTagPrefix.View, value: "home" }];
      addTags([...tags]);
    });
    fetch();

    onMounted(async () => {
      const headers = {
        Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
      };

      const settings = await axios.get(`${baseUrl}rest/V1/utech/settings`, {
        headers,
      });
      coupon_enabled.value = settings?.data[0]?.coupon_enabled_homepage;
      bannerSlideId.value = settings?.data[0]?.homepage_banner;
      miniBannerId.value = settings?.data[0]?.mini_banner;
      homeCategoryId.value = settings?.data[0]?.home_category_id;
      banner_mobile_width.value = settings?.data[0]?.banner_mobile_width;
      banner_mobile_height.value = settings?.data[0]?.banner_mobile_height;
      const categoryIds = ref([]);
      Object.keys(homeCategoryId.value).forEach(async (key, index) => {
        const v = homeCategoryId.value[key];
        categoryIds.value.push(Number(v.id));
      });
      const { data } = await query<{ categoryList: [CategoryTree] }>(
        CategoryProductsGql,
        {
          filters: {
            ids: { in: categoryIds.value },
          },
          pageSize: 8,
        },
      );
      if (typeof data.categoryList !== "undefined" && data.categoryList) {
        data.categoryList.map((value, index) => {
          categoryData.value[index] = {
            id: value.id,
            name: value.name,
            url_key: value.url_key,
            url_path: value.url_path,
            path: value.path,
            image: value.image,
            top_content: value.top_content,
            bottom_content: value.bottom_content,
            products: value.products.items,
          };
          i.value++;
          if (i.value === Object.keys(homeCategoryId.value).length) {
            isLoaded.value = true;
          }
        });
      }
      document.body.setAttribute("id", "html-body");
      document.body.setAttribute("class", "home-page");
    });

    return {
      baseMediaUrl,
      categoryData,
      isLoaded,
      bannerSlideId,
      miniBannerId,
      coupon_enabled,
      logo_web,
      banner_mobile_width,
      banner_mobile_height,
      strapiBlog,
    };
  },
  head() {
    return getMetaInfo({
      image: {
        url: this.logo_web,
      },
    });
  },
});
